<template>
  <div id="app">
    <router-view/>

  </div>
</template>

<script>

export default {
  data() {
    return {

    }
  },
  metaInfo: {
    titleTemplate: 'ADLERHOF',
    htmlAttrs: {
      lang: 'de',
    },
    meta: [
      {name: 'description', content: `Café, Bistro & Bar | ADLERHOF`},
      {name: 'application-name', content: `Ob auf einen Café oder Drink – der Adlerhof ist mit frischem Brot, Bistro & Bar für alle Gelüste da.`},
      // Schema.org markup for Google+
      {itemprop: 'description', content: `Ob auf einen Café oder Drink – der Adlerhof ist mit frischem Brot, Bistro & Bar für alle Gelüste da.`},
      {name: 'robots', content: `index, follow`}
    ],
  },
}
</script>

<style lang="scss">
@import 'reset-css';
@import './assets/font/font.css';
@import './assets/css/variables.scss';

html {
  // Hide body before load
  opacity: 0;
  visibility: hidden;
  transition: opacity .75s ease-in-out;

  &.body-is-loaded {
    opacity: 1;
    visibility: visible;
  }
}

html,
body {
  background-color: var(--c-mint-05);
  color: var(--c-olive-05);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  overflow: visible;
  height: calc(var(--vh) * 100);
}

svg {
  overflow: visible;
  width: 100%;
  height: 100%;
}

</style>
